<template>
  <div class="p-4">
    <!-- Corporate Customer Information -->
    <div
      class="row row-cols-1"
      v-if="loginUserObj.role_id == 9 && profileInformationCC"
    >
      <div class="col mb-4">
        <div class="card">
          <div class="card-header">
            <h5 class="mb-0">Profile</h5>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-lg-6 col-md-6 col-12">
                <dl class="row">
                  <dt class="col-sm-3">Given name</dt>
                  <dd class="col-sm-9">
                    {{ profileInformationCC.first_name }}
                  </dd>
                  <dt class="col-sm-3">Middle name</dt>
                  <dd class="col-sm-9">
                    {{ profileInformationCC.middle_name }}
                  </dd>
                  <dt class="col-sm-3">Family name</dt>
                  <dd class="col-sm-9">
                    {{ profileInformationCC.last_name }}
                  </dd>
                </dl>
              </div>
              <div class="col-lg-6 col-md-6 col-12">
                <dl class="row">
                  <dt class="col-sm-3">Email</dt>
                  <dd class="col-sm-9">
                    {{ profileInformationCC.email }}
                  </dd>
                  <dt class="col-sm-3">Mobile no</dt>
                  <dd class="col-sm-9">
                    {{ profileInformationCC.mobile_no }}
                  </dd>
                  <dt class="col-sm-3">Date of birth</dt>
                  <dd class="col-sm-9">
                    {{ profileInformationCC.date_of_birth }}
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card" v-for="pro in getProjectsListByUser" :key="pro.id">
      <div class="card-header">
        <h5 class="mb-0">
          {{ pro.profile_info.get_parent.business_name }}
          <span class="text-info"> | </span>
          <span class="fs-800 fw-600">Ref:{{ pro.id }}</span>
        </h5>
      </div>

      <div class="card-body">
        <div class="row row-cols-1 row-cols-md-3 row-cols-lg-3 row-cols-xl-3">
          <div
            class="col mb-3"
            v-for="(item, index) in pro.products"
            :key="index"
          >
            <div class="card sits-card border-info">
              <div class="card-header">
                <h5 class="mb-0">
                  {{ item.name }}
                  <span
                    v-if="item.name != item.product_category"
                    class="fs-700"
                  >
                    {{ item.product_category }}</span
                  >
                </h5>
              </div>
              <div class="card-body">
                <dl class="row">
                  <dt class="col-sm-4">Ref.number</dt>
                  <dd class="col-sm-8">
                    <span v-if="fnGetCardInfo(item.id, pro)">{{
                      fnGetCardInfo(item.id, pro).id
                    }}</span>
                  </dd>
                  <dt class="col-sm-4">Date of created</dt>
                  <dd class="col-sm-8">
                    {{ pro.created_at | dateConvertDDMMYYYY }}
                  </dd>
                  <dt class="col-sm-4">Last update</dt>
                  <dd class="col-sm-8">
                    {{ pro.updated_at | dateConvertDDMMYYYY }}
                  </dd>
                  <dt class="col-sm-4">Status</dt>
                  <dd class="col-sm-8">
                    <span
                      v-if="fnGetCardInfo(item.id, pro) == ''"
                      class="badge badge-dark text-white"
                      >Not Started</span
                    >
                    <span
                      v-if="
                        fnGetCardInfo(item.id, pro) &&
                        fnGetCardInfo(item.id, pro).status == 1
                      "
                      class="badge badge-secondary"
                      >Draft</span
                    >
                    <span
                      v-if="
                        fnGetCardInfo(item.id, pro) &&
                        fnGetCardInfo(item.id, pro).status == 2
                      "
                      class="badge badge-primary"
                      >Submitted</span
                    >
                    <span
                      v-if="
                        fnGetCardInfo(item.id, pro) &&
                        fnGetCardInfo(item.id, pro).status == 3
                      "
                      class="badge badge-warning"
                      >MIR</span
                    >

                    <span
                      v-if="
                        fnGetCardInfo(item.id, pro) &&
                        fnGetCardInfo(item.id, pro).status == 4
                      "
                      class="badge badge-info"
                      >WFR</span
                    >
                    <span
                      v-if="
                        fnGetCardInfo(item.id, pro) &&
                        fnGetCardInfo(item.id, pro).status == 5
                      "
                      class="badge badge-success"
                      >Completed</span
                    >
                    <span
                      v-if="
                        fnGetCardInfo(item.id, pro) &&
                        fnGetCardInfo(item.id, pro).status == 6
                      "
                      class="badge badge-dark"
                      >Cancelled</span
                    >
                    <span
                      v-if="
                        fnGetCardInfo(item.id, pro) &&
                        fnGetCardInfo(item.id, pro).status == 7
                      "
                      class="badge badge-light"
                      >Ready to Check</span
                    >
                  </dd>
                </dl>
                <div class="row">
                  <div class="col-12 text-right">
                    <button
                      v-if="fnGetStatus(item.id, pro) == 0"
                      class="btn btn-dark btn-sm mr-2 text-white"
                      :style="'color:#ffffff'"
                      @click="fnStartCheck(pro.id, item.id)"
                    >
                      Start
                    </button>
                    <button
                      v-if="fnGetStatus(item.id, pro) == 1"
                      class="btn btn-warning btn-sm mr-2 text-white"
                      @click="fnUpdateCheck(pro, item.id)"
                    >
                      Edit
                    </button>
                    <!-- Other check view -->
                    <button
                      v-if="fnGetStatus(item.id, pro) > 1"
                      class="btn btn-info btn-sm mr-2"
                      @click="fnViewCheck(pro, item.id)"
                    >
                      View
                    </button>
                    <button
                      v-if="
                        fnGetStatus(item.id, pro) == 5 && canViewCertificate
                      "
                      class="btn btn-info btn-sm mr-2"
                      @click="fnViewCertificate(pro, item.id)"
                    >
                      Certificate
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import * as authService from "../../services/auth.service";
import * as encodeDecodeService from "../../services/encodeDecode.service";
export default {
  data() {
    return {
      loginUserObj: {},
      id: "",
      shId: 3,
      projectId: "",
      parentId: "", // This user belongs to Partner or Corporate manager
      customerId: "", // Customer Id
      checkId: "", // Product id
      stepId: "", // Police check
      mode: "new",
      canViewCertificate: false,
    };
  },
  computed: {
    ...mapGetters([
      "getUserIndividualPoliceChecksAll",
      "getProjectsListByUser",
      "getStakeholderSettings",
    ]),

    policeCheckList: function () {
      if (
        this.getUserIndividualPoliceChecksAll &&
        this.getUserIndividualPoliceChecksAll.police_checks
      ) {
        return this.getUserIndividualPoliceChecksAll.police_checks;
      } else {
        return [];
      }
    },
    /**
     * Get User information from getProjectsListByUser
     */
    profileInformationCC: function () {
      if (
        this.getProjectsListByUser[0] &&
        this.getProjectsListByUser[0].profile_info
      ) {
        return this.getProjectsListByUser[0].profile_info;
      } else {
        return [];
      }
    },
  },
  methods: {
    ...mapActions([
      "fetchUserIndividualPoliceChecksAll",
      "fetchProjectsListByUser",
      "fetchStakeholderSettings",
    ]),
    // View Police check Applications
    fnViewApplication(policCheck) {
      this.$router.push({
        name: "PoliceCheckViewCC",
        params: {
          userId: encodeDecodeService.encode(this.loginUserObj.id),
          id: encodeDecodeService.encode(policCheck.id),
        },
      });
    },
    fnEditApplication(policCheck) {
      this.$router.push({
        name: "PoliceCheckIUStepsEdit",
        params: {
          userId: encodeDecodeService.encode(this.loginUserObj.id),
          id: encodeDecodeService.encode(policCheck.id),
        },
      });
    },
    fnDownloadCertificate(policCheck) {
      window.open(`${policCheck.police_check_certificate_from_nss}`);
    },

    fnStartCheck(projectId, checkId) {
      this.mode = "new";
      /**
       * shId (Stake Holder)
       * 1 Individual
       * 2 Partner
       * 3 Corporate
       */
      if (checkId == 1) {
        this.$router.push({
          name: "checkPoliceStep1CU",
          params: {
            id: encodeDecodeService.cyEncrypt(this.id),
            shId: encodeDecodeService.cyEncrypt(this.shId),
            projectId: encodeDecodeService.cyEncrypt(projectId),
            parentId: encodeDecodeService.cyEncrypt(this.parentId),
            customerId: encodeDecodeService.cyEncrypt(this.customerId),
            checkId: encodeDecodeService.cyEncrypt(checkId),
            mode: encodeDecodeService.cyEncrypt(this.mode),
          },
        });
      } else if (checkId == 2) {
        this.$router.push({
          name: "checkPoliceStep1CU",
          params: {
            id: encodeDecodeService.cyEncrypt(this.id),
            shId: encodeDecodeService.cyEncrypt(this.shId),
            projectId: encodeDecodeService.cyEncrypt(projectId),
            parentId: encodeDecodeService.cyEncrypt(this.parentId),
            customerId: encodeDecodeService.cyEncrypt(this.customerId),
            checkId: encodeDecodeService.cyEncrypt(checkId),
            mode: encodeDecodeService.cyEncrypt(this.mode),
          },
        });
      } else if (checkId == 3) {
        this.$router.push({
          name: "checkRightToWorkInAustraliaIU",
          params: {
            projectId: encodeDecodeService.cyEncrypt(projectId),
            userId: encodeDecodeService.cyEncrypt(this.loginUserObj.id),
            checkId: encodeDecodeService.cyEncrypt(checkId),
          },
        });
      } else if (checkId == 4) {
        this.$router.push({
          name: "checkCharacterReferenceIU",
          params: {
            projectId: encodeDecodeService.cyEncrypt(projectId),
            userId: encodeDecodeService.cyEncrypt(this.loginUserObj.id),
            checkId: encodeDecodeService.cyEncrypt(checkId),
          },
        });
      } else if (checkId == 5) {
        this.$router.push({
          name: "checkAcademicQualificationIU",
          params: {
            projectId: encodeDecodeService.cyEncrypt(projectId),
            userId: encodeDecodeService.cyEncrypt(this.loginUserObj.id),
            checkId: encodeDecodeService.cyEncrypt(checkId),
          },
        });
      } else if (checkId == 6) {
        this.$router.push({
          name: "checkProfessionalMembershipIU",
          params: {
            projectId: encodeDecodeService.cyEncrypt(projectId),
            userId: encodeDecodeService.cyEncrypt(this.loginUserObj.id),
            checkId: encodeDecodeService.cyEncrypt(checkId),
          },
        });
      } else if (checkId == 7) {
        this.$router.push({
          name: "checkEmploymentHistoryIU",
          params: {
            projectId: encodeDecodeService.cyEncrypt(projectId),
            userId: encodeDecodeService.cyEncrypt(this.loginUserObj.id),
            checkId: encodeDecodeService.cyEncrypt(checkId),
          },
        });
      } else if (checkId == 8) {
        this.$router.push({
          name: "checkBankruptcyRecordIU",
          params: {
            projectId: encodeDecodeService.cyEncrypt(projectId),
            userId: encodeDecodeService.cyEncrypt(this.loginUserObj.id),
            checkId: encodeDecodeService.cyEncrypt(checkId),
          },
        });
      } else if (checkId == 9) {
        this.$router.push({
          name: "checkDriversLicenceTrafficOffenceIU",
          params: {
            projectId: encodeDecodeService.cyEncrypt(projectId),
            userId: encodeDecodeService.cyEncrypt(this.loginUserObj.id),
            checkId: encodeDecodeService.cyEncrypt(checkId),
          },
        });
      }
    },
    fnUpdateCheck(pro, checkId) {
      this.mode = "edit";
      /**
       * sh (Stake Holder)
       * 1 Individual
       * 2 Partner
       * 3 Corporate
       */
      if (checkId == 1) {
        // Get the Police check id form check_police_standard
        if (pro && pro.check_police_standard && pro.check_police_standard.id) {
          this.id = pro.check_police_standard.id;
        }
        this.$router.push({
          name: "checkPoliceStep1CU",
          params: {
            id: encodeDecodeService.cyEncrypt(this.id),
            shId: encodeDecodeService.cyEncrypt(this.shId),
            projectId: encodeDecodeService.cyEncrypt(pro.id),
            parentId: encodeDecodeService.cyEncrypt(this.parentId),
            customerId: encodeDecodeService.cyEncrypt(this.customerId),
            checkId: encodeDecodeService.cyEncrypt(checkId),
            stepId: encodeDecodeService.cyEncrypt(1),
            mode: encodeDecodeService.cyEncrypt(this.mode),
          },
        });
      } else if (checkId == 2) {
        // Get the Police check id form check_police_volunteer
        if (
          pro &&
          pro.check_police_volunteer &&
          pro.check_police_volunteer.id
        ) {
          this.id = pro.check_police_volunteer.id;
        }
        this.$router.push({
          name: "checkPoliceStep1CU",
          params: {
            id: encodeDecodeService.cyEncrypt(this.id),
            shId: encodeDecodeService.cyEncrypt(this.shId),
            projectId: encodeDecodeService.cyEncrypt(pro.id),
            parentId: encodeDecodeService.cyEncrypt(this.parentId),
            customerId: encodeDecodeService.cyEncrypt(this.customerId),
            checkId: encodeDecodeService.cyEncrypt(checkId),
            stepId: encodeDecodeService.cyEncrypt(1),
            mode: encodeDecodeService.cyEncrypt(this.mode),
          },
        });
      } else if (checkId == 3) {
        this.$router.push({
          name: "checkRightToWorkInAustraliaIU",
          params: {
            projectId: encodeDecodeService.cyEncrypt(pro.id),
            userId: encodeDecodeService.cyEncrypt(this.loginUserObj.id),
            checkId: encodeDecodeService.cyEncrypt(checkId),
          },
        });
      } else if (checkId == 4) {
        this.$router.push({
          name: "checkCharacterReferenceIU",
          params: {
            projectId: encodeDecodeService.cyEncrypt(pro.id),
            userId: encodeDecodeService.cyEncrypt(this.loginUserObj.id),
            checkId: encodeDecodeService.cyEncrypt(checkId),
          },
        });
      } else if (checkId == 5) {
        this.$router.push({
          name: "checkAcademicQualificationIU",
          params: {
            projectId: encodeDecodeService.cyEncrypt(pro.id),
            userId: encodeDecodeService.cyEncrypt(this.loginUserObj.id),
            checkId: encodeDecodeService.cyEncrypt(checkId),
          },
        });
      } else if (checkId == 6) {
        this.$router.push({
          name: "checkProfessionalMembershipIU",
          params: {
            projectId: encodeDecodeService.cyEncrypt(pro.id),
            userId: encodeDecodeService.cyEncrypt(this.loginUserObj.id),
            checkId: encodeDecodeService.cyEncrypt(checkId),
          },
        });
      } else if (checkId == 7) {
        this.$router.push({
          name: "checkEmploymentHistoryIU",
          params: {
            projectId: encodeDecodeService.cyEncrypt(pro.id),
            userId: encodeDecodeService.cyEncrypt(this.loginUserObj.id),
            checkId: encodeDecodeService.cyEncrypt(checkId),
          },
        });
      } else if (checkId == 8) {
        this.$router.push({
          name: "checkBankruptcyRecordIU",
          params: {
            projectId: encodeDecodeService.cyEncrypt(pro.id),
            userId: encodeDecodeService.cyEncrypt(this.loginUserObj.id),
            checkId: encodeDecodeService.cyEncrypt(checkId),
          },
        });
      } else if (checkId == 9) {
        this.$router.push({
          name: "checkDriversLicenceTrafficOffenceIU",
          params: {
            projectId: encodeDecodeService.cyEncrypt(pro.id),
            userId: encodeDecodeService.cyEncrypt(this.loginUserObj.id),
            checkId: encodeDecodeService.cyEncrypt(checkId),
          },
        });
      }
    },

    fnViewCheck(pro, checkId) {
      if (checkId == 1) {
        // Get the Police check id form check_police_standard
        if (pro && pro.check_police_standard && pro.check_police_standard.id) {
          this.id = pro.check_police_standard.id;
        }

        this.$router.push({
          name: "PoliceCheckViewCC",
          params: {
            userId: encodeDecodeService.encode(this.loginUserObj.id),
            id: encodeDecodeService.encode(this.id),
          },
        });
      } else if (checkId == 2) {
        // Get the Police check id form check_police_volunteer
        if (
          pro &&
          pro.check_police_volunteer &&
          pro.check_police_volunteer.id
        ) {
          this.id = pro.check_police_volunteer.id;
        }
        this.$router.push({
          name: "PoliceCheckViewCC",
          params: {
            userId: encodeDecodeService.encode(this.loginUserObj.id),
            id: encodeDecodeService.encode(this.id),
          },
        });
      } else if (checkId == 3) {
        this.$router.push({
          name: "checkRightToWorkInAustraliaViewCC",
          params: {
            projectId: encodeDecodeService.cyEncrypt(pro.id),
            userId: encodeDecodeService.cyEncrypt(this.loginUserObj.id),
            checkId: encodeDecodeService.cyEncrypt(checkId),
          },
        });
      } else if (checkId == 4) {
        this.$router.push({
          name: "checkCharacterReferenceViewCC",
          params: {
            projectId: encodeDecodeService.cyEncrypt(pro.id),
            userId: encodeDecodeService.cyEncrypt(this.loginUserObj.id),
            checkId: encodeDecodeService.cyEncrypt(checkId),
          },
        });
      } else if (checkId == 5) {
        this.$router.push({
          name: "checkAcademicQualificationViewCC",
          params: {
            projectId: encodeDecodeService.cyEncrypt(pro.id),
            userId: encodeDecodeService.cyEncrypt(this.loginUserObj.id),
            checkId: encodeDecodeService.cyEncrypt(checkId),
          },
        });
      } else if (checkId == 6) {
        this.$router.push({
          name: "checkProfessionalMembershipViewCC",
          params: {
            projectId: encodeDecodeService.cyEncrypt(pro.id),
            userId: encodeDecodeService.cyEncrypt(this.loginUserObj.id),
            checkId: encodeDecodeService.cyEncrypt(checkId),
          },
        });
      } else if (checkId == 7) {
        this.$router.push({
          name: "checkEmploymentHistoryViewCC",
          params: {
            projectId: encodeDecodeService.cyEncrypt(pro.id),
            userId: encodeDecodeService.cyEncrypt(this.loginUserObj.id),
            checkId: encodeDecodeService.cyEncrypt(checkId),
          },
        });
      } else if (checkId == 8) {
        this.$router.push({
          name: "checkBankruptcyRecordViewCC",
          params: {
            projectId: encodeDecodeService.cyEncrypt(pro.id),
            userId: encodeDecodeService.cyEncrypt(this.loginUserObj.id),
            checkId: encodeDecodeService.cyEncrypt(checkId),
          },
        });
      } else if (checkId == 9) {
        this.$router.push({
          name: "checkDriversLicenceTrafficOffenceViewCC",
          params: {
            projectId: encodeDecodeService.cyEncrypt(pro.id),
            userId: encodeDecodeService.cyEncrypt(this.loginUserObj.id),
            checkId: encodeDecodeService.cyEncrypt(checkId),
          },
        });
      }
    },
    fnGetStatus(productId, project) {
      if (productId == 1) {
        if (
          project &&
          project.check_police_standard &&
          project.check_police_standard.status
        ) {
          return project.check_police_standard.status;
        } else {
          return 0;
        }
      }
      if (productId == 2) {
        if (
          project &&
          project.check_police_volunteer &&
          project.check_police_volunteer.status
        ) {
          return project.check_police_volunteer.status;
        } else {
          return 0;
        }
      }

      if (productId == 3) {
        if (
          project &&
          project.check_right_work_australia &&
          project.check_right_work_australia.status
        ) {
          return project.check_right_work_australia.status;
        } else {
          return 0;
        }
      }

      if (productId == 5) {
        if (
          project &&
          project.check_academic_qualification &&
          project.check_academic_qualification.status
        ) {
          return project.check_academic_qualification.status;
        } else {
          return 0;
        }
      }

      if (productId == 6) {
        if (
          project &&
          project.check_professional_membership &&
          project.check_professional_membership.status
        ) {
          return project.check_professional_membership.status;
        } else {
          return 0;
        }
      }

      if (productId == 7) {
        if (
          project &&
          project.check_employment_history &&
          project.check_employment_history[0]
        ) {
          let arr = project.check_employment_history[0];
          return arr.status;
        } else {
          return 0;
        }
      }

      if (productId == 8) {
        if (
          project &&
          project.check_bankruptcy_record &&
          project.check_bankruptcy_record.status
        ) {
          return project.check_bankruptcy_record.status;
        } else {
          return 0;
        }
      }

      if (productId == 9) {
        if (
          project &&
          project.check_drivers_offence &&
          project.check_drivers_offence.status
        ) {
          return project.check_drivers_offence.status;
        } else {
          return 0;
        }
      }
    },
    fnGetCardInfo(productId, project) {
      if (productId == 1) {
        if (project && project.check_police_standard) {
          return project.check_police_standard;
        } else {
          return "";
        }
      }
      if (productId == 2) {
        if (project && project.check_police_volunteer) {
          return project.check_police_volunteer;
        } else {
          return "";
        }
      }

      if (productId == 3) {
        if (project && project.check_right_work_australia) {
          return project.check_right_work_australia;
        } else {
          return "";
        }
      }

      if (productId == 5) {
        if (project && project.check_academic_qualification) {
          return project.check_academic_qualification;
        } else {
          return "";
        }
      }

      if (productId == 6) {
        if (project && project.check_professional_membership) {
          return project.check_professional_membership;
        } else {
          return "";
        }
      }

      if (productId == 7) {
        if (
          project &&
          project.check_employment_history &&
          project.check_employment_history[0]
        ) {
          let arr = project.check_employment_history[0];
          return arr;
        } else {
          return "";
        }
      }

      if (productId == 8) {
        if (project && project.check_bankruptcy_record) {
          return project.check_bankruptcy_record;
        } else {
          return "";
        }
      }

      if (productId == 9) {
        if (project && project.check_drivers_offence) {
          return project.check_drivers_offence;
        } else {
          return "";
        }
      }
    },
    fnViewCertificate(pro, productId) {
      if (productId == 1) {
        // Get the Police check id form check_police_standard
        if (pro && pro.check_police_standard && pro.check_police_standard.id) {
          this.id = pro.check_police_standard.id;
        }

        this.$router.push({
          name: "checkPoliceCertificateCC",
          params: {
            userId: encodeDecodeService.encode(this.loginUserObj.id),
            checkId: encodeDecodeService.encode(this.id),
          },
        });
      } else if (productId == 2) {
        // Get the Police check id form check_police_volunteer
        if (
          pro &&
          pro.check_police_volunteer &&
          pro.check_police_volunteer.id
        ) {
          this.id = pro.check_police_volunteer.id;
        }
        this.$router.push({
          name: "checkPoliceCertificateCC",
          params: {
            userId: encodeDecodeService.encode(this.loginUserObj.id),
            checkId: encodeDecodeService.encode(this.id),
          },
        });
      } else if (productId == 3) {
        this.$router.push({
          name: "checkRightToWorkInAustraliaCertificateCC",
          params: {
            projectId: encodeDecodeService.cyEncrypt(productId),
            userId: encodeDecodeService.cyEncrypt(this.loginUserObj.id),
            checkId: encodeDecodeService.cyEncrypt(this.id),
          },
        });
      } else if (productId == 4) {
        this.$router.push({
          name: "checkRightToWorkInAustraliaCertificateCC",
          params: {
            projectId: encodeDecodeService.cyEncrypt(productId),
            userId: encodeDecodeService.cyEncrypt(this.loginUserObj.id),
            checkId: encodeDecodeService.cyEncrypt(this.id),
          },
        });
      } else if (productId == 5) {
        this.$router.push({
          name: "checkAcademicQualificationCertificateCC",
          params: {
            projectId: encodeDecodeService.cyEncrypt(productId),
            userId: encodeDecodeService.cyEncrypt(this.loginUserObj.id),
            checkId: encodeDecodeService.cyEncrypt(this.id),
          },
        });
      } else if (productId == 6) {
        this.$router.push({
          name: "checkProfessionalMembershipCertificateCC",
          params: {
            projectId: encodeDecodeService.cyEncrypt(productId),
            userId: encodeDecodeService.cyEncrypt(this.loginUserObj.id),
            checkId: encodeDecodeService.cyEncrypt(this.id),
          },
        });
      } else if (productId == 7) {
        this.$router.push({
          name: "checkEmploymentHistoryCertificateCC",
          params: {
            projectId: encodeDecodeService.cyEncrypt(productId),
            userId: encodeDecodeService.cyEncrypt(this.loginUserObj.id),
            checkId: encodeDecodeService.cyEncrypt(this.id),
          },
        });
      } else if (productId == 8) {
        this.$router.push({
          name: "checkBankruptcyRecordCertificateCC",
          params: {
            projectId: encodeDecodeService.cyEncrypt(productId),
            userId: encodeDecodeService.cyEncrypt(this.loginUserObj.id),
            checkId: encodeDecodeService.cyEncrypt(this.id),
          },
        });
      } else if (productId == 9) {
        this.$router.push({
          name: "checkDriversLicenceTrafficOffenceCertificateCC",
          params: {
            projectId: encodeDecodeService.cyEncrypt(productId),
            userId: encodeDecodeService.cyEncrypt(this.loginUserObj.id),
            checkId: encodeDecodeService.cyEncrypt(this.id),
          },
        });
      }
    },
  },
  created() {
    this.loginUserObj = authService.getUserFromToken();
    this.fetchStakeholderSettings(this.loginUserObj.parent_id);

    if (this.loginUserObj.role_id == 9) {
      this.customerId = this.loginUserObj.id;
      // Get Projects based on user id
      this.fetchProjectsListByUser(this.customerId);
    }

    if (this.loginUserObj.role_id == 3) {
      this.customerId = this.loginUserObj.id;
      // Get Projects based on user id
      this.fetchUserIndividualPoliceChecksAll(this.customerId);
    }
  },
  watch: {
    getStakeholderSettings(val) {
      if (
        val &&
        val.settings &&
        val.settings.allow_end_customer_to_view_and_notify_check_complete &&
        val.settings.allow_end_customer_to_view_and_notify_check_complete == 1
      ) {
        this.canViewCertificate = true;
        return true;
      }
      this.canViewCertificate = false;
      return false;
    },
  },
};
</script>